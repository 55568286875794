import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { Pagination } from '@/types/common/pagination.type';
import type {
  CreateUpdateProductDiscountRequest,
  ProductDiscountListRequestModel
} from '@/types/product-discount/product-discount-request.type';
import type {
  ProductDiscountDetailResponse,
  ProductDiscountListResponseModel,
  ProductDiscountResponse
} from '@/types/product-discount/product-discount-response.type';
import { camelToSnake } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902643
export const fetchProductDiscountListApi = async (
  productNo: number,
  params: ProductDiscountListRequestModel
): Promise<ProductDiscountListResponseModel | undefined> => {
  const { data } = await useRequest<ProductDiscountListResponseModel>(
    `${apiUrl}/products/${productNo}/discount`,
    {
      params: camelToSnake(params)
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902645
export const fetchProductDiscountApi = async (
  productNo: number,
  discountNo: number
): Promise<ProductDiscountDetailResponse | undefined> => {
  const { data } = await useRequest<ProductDiscountDetailResponse>(
    `${apiUrl}/products/${productNo}/discount/${discountNo}`
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902641
export const createProductDiscountApi = async (
  productNo: number,
  params: CreateUpdateProductDiscountRequest
): Promise<ProductDiscountResponse | undefined> => {
  const { data } = await useRequest<ProductDiscountResponse>(
    `${apiUrl}/products/${productNo}/discount`,
    {
      showCommonError: false,
      data: camelToSnake(params),
      method: ApiMethod.Post
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902647
export const updateProductDiscountApi = async (
  productNo: number,
  discountNo: number,
  params: CreateUpdateProductDiscountRequest
): Promise<ProductDiscountResponse | undefined> => {
  const { data } = await useRequest<ProductDiscountResponse>(
    `${apiUrl}/products/${productNo}/discount/${discountNo}`,
    {
      showCommonError: false,
      data: camelToSnake(params),
      method: ApiMethod.Put
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=454902651
export const deleteProductDiscountApi = async (
  productNo: number,
  discountNo: number
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(
    `${apiUrl}/products/${productNo}/discount/${discountNo}`,
    {
      method: ApiMethod.Delete
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=491588921
export const fetchProductDiscountsApi = async (
  productNos: number[]
): Promise<Pagination<ProductDiscountResponse[]> | undefined> => {
  const { data } = await useRequest<Pagination<ProductDiscountResponse[]>>(
    `${apiUrl}/products/discount`,
    {
      params: camelToSnake({ productNos: productNos.join(',') })
    }
  );

  return data;
};
